@import url(//hello.myfonts.net/count/38fdad);
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 860px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1145px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-ty-1, .col-ty-2, .col-ty-3, .col-ty-4, .col-ty-5, .col-ty-6, .col-ty-7, .col-ty-8, .col-ty-9, .col-ty-10, .col-ty-11, .col-ty-12, .col-ty,
.col-ty-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 480px) {
  .col-ty {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-ty-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-ty-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-ty-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-ty-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-ty-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-ty-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-ty-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-ty-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-ty-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-ty-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-ty-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-ty-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-ty-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-ty-first {
    order: -1; }
  .order-ty-last {
    order: 13; }
  .order-ty-0 {
    order: 0; }
  .order-ty-1 {
    order: 1; }
  .order-ty-2 {
    order: 2; }
  .order-ty-3 {
    order: 3; }
  .order-ty-4 {
    order: 4; }
  .order-ty-5 {
    order: 5; }
  .order-ty-6 {
    order: 6; }
  .order-ty-7 {
    order: 7; }
  .order-ty-8 {
    order: 8; }
  .order-ty-9 {
    order: 9; }
  .order-ty-10 {
    order: 10; }
  .order-ty-11 {
    order: 11; }
  .order-ty-12 {
    order: 12; }
  .offset-ty-0 {
    margin-left: 0; }
  .offset-ty-1 {
    margin-left: 8.33333%; }
  .offset-ty-2 {
    margin-left: 16.66667%; }
  .offset-ty-3 {
    margin-left: 25%; }
  .offset-ty-4 {
    margin-left: 33.33333%; }
  .offset-ty-5 {
    margin-left: 41.66667%; }
  .offset-ty-6 {
    margin-left: 50%; }
  .offset-ty-7 {
    margin-left: 58.33333%; }
  .offset-ty-8 {
    margin-left: 66.66667%; }
  .offset-ty-9 {
    margin-left: 75%; }
  .offset-ty-10 {
    margin-left: 83.33333%; }
  .offset-ty-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 479.98px) {
  .table-responsive-ty {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-ty > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #000;
    background-color: #fff;
    border-color: #404040;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #000;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 2px; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 768px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.7rem 30px;
  font-size: 1.125rem;
  line-height: 1.5;
  border-radius: 50px;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 60px; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 40px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #000;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 479.98px) {
  .navbar-expand-ty > .container,
  .navbar-expand-ty > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 480px) {
  .navbar-expand-ty {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-ty .navbar-nav {
      flex-direction: row; }
      .navbar-expand-ty .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-ty .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-ty > .container,
    .navbar-expand-ty > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-ty .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-ty .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 768px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 480px) {
  .d-ty-none {
    display: none !important; }
  .d-ty-inline {
    display: inline !important; }
  .d-ty-inline-block {
    display: inline-block !important; }
  .d-ty-block {
    display: block !important; }
  .d-ty-table {
    display: table !important; }
  .d-ty-table-row {
    display: table-row !important; }
  .d-ty-table-cell {
    display: table-cell !important; }
  .d-ty-flex {
    display: flex !important; }
  .d-ty-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 480px) {
  .flex-ty-row {
    flex-direction: row !important; }
  .flex-ty-column {
    flex-direction: column !important; }
  .flex-ty-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-ty-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-ty-wrap {
    flex-wrap: wrap !important; }
  .flex-ty-nowrap {
    flex-wrap: nowrap !important; }
  .flex-ty-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-ty-fill {
    flex: 1 1 auto !important; }
  .flex-ty-grow-0 {
    flex-grow: 0 !important; }
  .flex-ty-grow-1 {
    flex-grow: 1 !important; }
  .flex-ty-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-ty-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-ty-start {
    justify-content: flex-start !important; }
  .justify-content-ty-end {
    justify-content: flex-end !important; }
  .justify-content-ty-center {
    justify-content: center !important; }
  .justify-content-ty-between {
    justify-content: space-between !important; }
  .justify-content-ty-around {
    justify-content: space-around !important; }
  .align-items-ty-start {
    align-items: flex-start !important; }
  .align-items-ty-end {
    align-items: flex-end !important; }
  .align-items-ty-center {
    align-items: center !important; }
  .align-items-ty-baseline {
    align-items: baseline !important; }
  .align-items-ty-stretch {
    align-items: stretch !important; }
  .align-content-ty-start {
    align-content: flex-start !important; }
  .align-content-ty-end {
    align-content: flex-end !important; }
  .align-content-ty-center {
    align-content: center !important; }
  .align-content-ty-between {
    align-content: space-between !important; }
  .align-content-ty-around {
    align-content: space-around !important; }
  .align-content-ty-stretch {
    align-content: stretch !important; }
  .align-self-ty-auto {
    align-self: auto !important; }
  .align-self-ty-start {
    align-self: flex-start !important; }
  .align-self-ty-end {
    align-self: flex-end !important; }
  .align-self-ty-center {
    align-self: center !important; }
  .align-self-ty-baseline {
    align-self: baseline !important; }
  .align-self-ty-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 480px) {
  .float-ty-left {
    float: left !important; }
  .float-ty-right {
    float: right !important; }
  .float-ty-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 480px) {
  .m-ty-0 {
    margin: 0 !important; }
  .mt-ty-0,
  .my-ty-0 {
    margin-top: 0 !important; }
  .mr-ty-0,
  .mx-ty-0 {
    margin-right: 0 !important; }
  .mb-ty-0,
  .my-ty-0 {
    margin-bottom: 0 !important; }
  .ml-ty-0,
  .mx-ty-0 {
    margin-left: 0 !important; }
  .m-ty-1 {
    margin: 0.25rem !important; }
  .mt-ty-1,
  .my-ty-1 {
    margin-top: 0.25rem !important; }
  .mr-ty-1,
  .mx-ty-1 {
    margin-right: 0.25rem !important; }
  .mb-ty-1,
  .my-ty-1 {
    margin-bottom: 0.25rem !important; }
  .ml-ty-1,
  .mx-ty-1 {
    margin-left: 0.25rem !important; }
  .m-ty-2 {
    margin: 0.5rem !important; }
  .mt-ty-2,
  .my-ty-2 {
    margin-top: 0.5rem !important; }
  .mr-ty-2,
  .mx-ty-2 {
    margin-right: 0.5rem !important; }
  .mb-ty-2,
  .my-ty-2 {
    margin-bottom: 0.5rem !important; }
  .ml-ty-2,
  .mx-ty-2 {
    margin-left: 0.5rem !important; }
  .m-ty-3 {
    margin: 1rem !important; }
  .mt-ty-3,
  .my-ty-3 {
    margin-top: 1rem !important; }
  .mr-ty-3,
  .mx-ty-3 {
    margin-right: 1rem !important; }
  .mb-ty-3,
  .my-ty-3 {
    margin-bottom: 1rem !important; }
  .ml-ty-3,
  .mx-ty-3 {
    margin-left: 1rem !important; }
  .m-ty-4 {
    margin: 1.5rem !important; }
  .mt-ty-4,
  .my-ty-4 {
    margin-top: 1.5rem !important; }
  .mr-ty-4,
  .mx-ty-4 {
    margin-right: 1.5rem !important; }
  .mb-ty-4,
  .my-ty-4 {
    margin-bottom: 1.5rem !important; }
  .ml-ty-4,
  .mx-ty-4 {
    margin-left: 1.5rem !important; }
  .m-ty-5 {
    margin: 3rem !important; }
  .mt-ty-5,
  .my-ty-5 {
    margin-top: 3rem !important; }
  .mr-ty-5,
  .mx-ty-5 {
    margin-right: 3rem !important; }
  .mb-ty-5,
  .my-ty-5 {
    margin-bottom: 3rem !important; }
  .ml-ty-5,
  .mx-ty-5 {
    margin-left: 3rem !important; }
  .p-ty-0 {
    padding: 0 !important; }
  .pt-ty-0,
  .py-ty-0 {
    padding-top: 0 !important; }
  .pr-ty-0,
  .px-ty-0 {
    padding-right: 0 !important; }
  .pb-ty-0,
  .py-ty-0 {
    padding-bottom: 0 !important; }
  .pl-ty-0,
  .px-ty-0 {
    padding-left: 0 !important; }
  .p-ty-1 {
    padding: 0.25rem !important; }
  .pt-ty-1,
  .py-ty-1 {
    padding-top: 0.25rem !important; }
  .pr-ty-1,
  .px-ty-1 {
    padding-right: 0.25rem !important; }
  .pb-ty-1,
  .py-ty-1 {
    padding-bottom: 0.25rem !important; }
  .pl-ty-1,
  .px-ty-1 {
    padding-left: 0.25rem !important; }
  .p-ty-2 {
    padding: 0.5rem !important; }
  .pt-ty-2,
  .py-ty-2 {
    padding-top: 0.5rem !important; }
  .pr-ty-2,
  .px-ty-2 {
    padding-right: 0.5rem !important; }
  .pb-ty-2,
  .py-ty-2 {
    padding-bottom: 0.5rem !important; }
  .pl-ty-2,
  .px-ty-2 {
    padding-left: 0.5rem !important; }
  .p-ty-3 {
    padding: 1rem !important; }
  .pt-ty-3,
  .py-ty-3 {
    padding-top: 1rem !important; }
  .pr-ty-3,
  .px-ty-3 {
    padding-right: 1rem !important; }
  .pb-ty-3,
  .py-ty-3 {
    padding-bottom: 1rem !important; }
  .pl-ty-3,
  .px-ty-3 {
    padding-left: 1rem !important; }
  .p-ty-4 {
    padding: 1.5rem !important; }
  .pt-ty-4,
  .py-ty-4 {
    padding-top: 1.5rem !important; }
  .pr-ty-4,
  .px-ty-4 {
    padding-right: 1.5rem !important; }
  .pb-ty-4,
  .py-ty-4 {
    padding-bottom: 1.5rem !important; }
  .pl-ty-4,
  .px-ty-4 {
    padding-left: 1.5rem !important; }
  .p-ty-5 {
    padding: 3rem !important; }
  .pt-ty-5,
  .py-ty-5 {
    padding-top: 3rem !important; }
  .pr-ty-5,
  .px-ty-5 {
    padding-right: 3rem !important; }
  .pb-ty-5,
  .py-ty-5 {
    padding-bottom: 3rem !important; }
  .pl-ty-5,
  .px-ty-5 {
    padding-left: 3rem !important; }
  .m-ty-n1 {
    margin: -0.25rem !important; }
  .mt-ty-n1,
  .my-ty-n1 {
    margin-top: -0.25rem !important; }
  .mr-ty-n1,
  .mx-ty-n1 {
    margin-right: -0.25rem !important; }
  .mb-ty-n1,
  .my-ty-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-ty-n1,
  .mx-ty-n1 {
    margin-left: -0.25rem !important; }
  .m-ty-n2 {
    margin: -0.5rem !important; }
  .mt-ty-n2,
  .my-ty-n2 {
    margin-top: -0.5rem !important; }
  .mr-ty-n2,
  .mx-ty-n2 {
    margin-right: -0.5rem !important; }
  .mb-ty-n2,
  .my-ty-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-ty-n2,
  .mx-ty-n2 {
    margin-left: -0.5rem !important; }
  .m-ty-n3 {
    margin: -1rem !important; }
  .mt-ty-n3,
  .my-ty-n3 {
    margin-top: -1rem !important; }
  .mr-ty-n3,
  .mx-ty-n3 {
    margin-right: -1rem !important; }
  .mb-ty-n3,
  .my-ty-n3 {
    margin-bottom: -1rem !important; }
  .ml-ty-n3,
  .mx-ty-n3 {
    margin-left: -1rem !important; }
  .m-ty-n4 {
    margin: -1.5rem !important; }
  .mt-ty-n4,
  .my-ty-n4 {
    margin-top: -1.5rem !important; }
  .mr-ty-n4,
  .mx-ty-n4 {
    margin-right: -1.5rem !important; }
  .mb-ty-n4,
  .my-ty-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-ty-n4,
  .mx-ty-n4 {
    margin-left: -1.5rem !important; }
  .m-ty-n5 {
    margin: -3rem !important; }
  .mt-ty-n5,
  .my-ty-n5 {
    margin-top: -3rem !important; }
  .mr-ty-n5,
  .mx-ty-n5 {
    margin-right: -3rem !important; }
  .mb-ty-n5,
  .my-ty-n5 {
    margin-bottom: -3rem !important; }
  .ml-ty-n5,
  .mx-ty-n5 {
    margin-left: -3rem !important; }
  .m-ty-auto {
    margin: auto !important; }
  .mt-ty-auto,
  .my-ty-auto {
    margin-top: auto !important; }
  .mr-ty-auto,
  .mx-ty-auto {
    margin-right: auto !important; }
  .mb-ty-auto,
  .my-ty-auto {
    margin-bottom: auto !important; }
  .ml-ty-auto,
  .mx-ty-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 480px) {
  .text-ty-left {
    text-align: left !important; }
  .text-ty-right {
    text-align: right !important; }
  .text-ty-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@font-face {
  font-family: 'lg';
  src: url("/static/fonts/lightgallery/lg.eot?n1z373");
  src: url("/static/fonts/lightgallery/lg.eot?#iefixn1z373") format("embedded-opentype"), url("/static/fonts/lightgallery/lg.woff?n1z373") format("woff"), url("/static/fonts/lightgallery/lg.ttf?n1z373") format("truetype"), url("/static/fonts/lightgallery/lg.svg?n1z373#lg") format("svg");
  font-weight: normal;
  font-style: normal; }

.lg-icon {
  font-family: 'lg';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev {
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  margin-top: -10px;
  padding: 8px 10px 9px;
  position: absolute;
  top: 50%;
  z-index: 1080;
  border: none;
  outline: none; }
  .lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
    pointer-events: none;
    opacity: 0.5; }
  .lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
    color: #FFF; }

.lg-actions .lg-next {
  right: 20px; }
  .lg-actions .lg-next:before {
    content: "\E095"; }

.lg-actions .lg-prev {
  left: 20px; }
  .lg-actions .lg-prev:after {
    content: "\E094"; }

@-webkit-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@keyframes lg-right-end {
  0% {
    left: 0; }
  50% {
    left: -30px; }
  100% {
    left: 0; } }

@-webkit-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-moz-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@-ms-keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

@keyframes lg-left-end {
  0% {
    left: 0; }
  50% {
    left: 30px; }
  100% {
    left: 0; } }

.lg-outer.lg-right-end .lg-object {
  -webkit-animation: lg-right-end 0.3s;
  -o-animation: lg-right-end 0.3s;
  animation: lg-right-end 0.3s;
  position: relative; }

.lg-outer.lg-left-end .lg-object {
  -webkit-animation: lg-left-end 0.3s;
  -o-animation: lg-left-end 0.3s;
  animation: lg-left-end 0.3s;
  position: relative; }

.lg-toolbar {
  z-index: 1082;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #000; }
  .lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear; }
    .lg-toolbar .lg-icon:hover {
      color: #FFF; }
  .lg-toolbar .lg-close:after {
    content: "\E070"; }
  .lg-toolbar .lg-download:after {
    content: "\E0F2"; }

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #EEE;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080; }
  .lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold; }
  .lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0; }

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev {
  opacity: 0;
  -webkit-transform: translate3d(-10px, 0, 0);
  transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5);
  opacity: 0;
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1; }

.lg-outer .lg-thumb-outer {
  background-color: #0D0A0A;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 1080;
  max-height: 350px;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }
  .lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer {
  -webkit-transform: translate3d(0, 0%, 0);
  transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb {
  padding: 10px 0;
  height: 100%;
  margin-bottom: -5px; }

.lg-outer .lg-thumb-item {
  border-radius: 5px;
  cursor: pointer;
  float: left;
  overflow: hidden;
  height: 100%;
  border: 2px solid #FFF;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media (min-width: 1025px) {
    .lg-outer .lg-thumb-item {
      -webkit-transition: border-color 0.25s ease;
      -o-transition: border-color 0.25s ease;
      transition: border-color 0.25s ease; } }
  .lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
    border-color: #a90707; }
  .lg-outer .lg-thumb-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html {
  -webkit-transition: bottom 0.25s ease;
  -o-transition: bottom 0.25s ease;
  transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px; }

.lg-outer .lg-toogle-thumb {
  background-color: #0D0A0A;
  border-radius: 2px 2px 0 0;
  color: #999;
  cursor: pointer;
  font-size: 24px;
  height: 39px;
  line-height: 27px;
  padding: 5px 0;
  position: absolute;
  right: 20px;
  text-align: center;
  top: -39px;
  width: 50px; }
  .lg-outer .lg-toogle-thumb:after {
    content: "\E1FF"; }
  .lg-outer .lg-toogle-thumb:hover {
    color: #FFF; }

.lg-outer .lg-video-cont {
  display: inline-block;
  vertical-align: middle;
  max-width: 1140px;
  max-height: 100%;
  width: 100%;
  padding: 0 5px; }

.lg-outer .lg-video {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative; }
  .lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .lg-outer .lg-video .lg-video-play {
    width: 84px;
    height: 59px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -42px;
    margin-top: -30px;
    z-index: 1080;
    cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video {
  -webkit-overflow-scrolling: touch;
  overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("/static/images/lightgallery/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("/static/images/lightgallery/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("/static/images/lightgallery/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play {
  background: url("/static/images/lightgallery/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("/static/images/lightgallery/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0; }

.lg-outer .lg-has-video .lg-video-object {
  visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play {
  display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object {
  visibility: visible; }

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1083;
  opacity: 0;
  -webkit-transition: opacity 0.08s ease 0s;
  -moz-transition: opacity 0.08s ease 0s;
  -o-transition: opacity 0.08s ease 0s;
  transition: opacity 0.08s ease 0s; }
  .lg-progress-bar .lg-progress {
    background-color: #a90707;
    height: 5px;
    width: 0; }
  .lg-progress-bar.lg-start .lg-progress {
    width: 100%; }
  .lg-show-autoplay .lg-progress-bar {
    opacity: 1; }

.lg-autoplay-button:after {
  content: "\E01D"; }
  .lg-show-autoplay .lg-autoplay-button:after {
    content: "\E01A"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transition-duration: 0s;
  transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s;
  transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

#lg-zoom-in:after {
  content: "\E311"; }

#lg-actual-size {
  font-size: 20px; }
  #lg-actual-size:after {
    content: "\E033"; }

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none; }
  #lg-zoom-out:after {
    content: "\E312"; }
  .lg-zoomed #lg-zoom-out {
    opacity: 1;
    pointer-events: auto; }

.lg-outer .lg-pager-outer {
  bottom: 60px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
  height: 10px; }
  .lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
    overflow: visible; }

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  margin: 0 5px; }
  .lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }
  .lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
    box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont {
  background-color: #fff;
  color: #FFF;
  bottom: 100%;
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  width: 120px;
  border-radius: 3px;
  -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s;
  -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s;
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0); }
  .lg-outer .lg-pager-thumb-cont img {
    width: 100%;
    height: 100%; }

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  -webkit-transition: box-shadow 0.3s ease 0s;
  -o-transition: box-shadow 0.3s ease 0s;
  transition: box-shadow 0.3s ease 0s;
  width: 12px; }
  .lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
    box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0; }

.lg-fullscreen:after {
  content: "\E20C"; }
  .lg-fullscreen-on .lg-fullscreen:after {
    content: "\E20D"; }

.lg-outer #lg-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  bottom: 0;
  cursor: default;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1081;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s;
  transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  opacity: 1;
  visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share {
  color: #FFF; }

.lg-outer .lg-dropdown {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50px;
  opacity: 0;
  visibility: hidden;
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  -webkit-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s;
  transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }
  .lg-outer .lg-dropdown:after {
    content: "";
    display: block;
    height: 0;
    width: 0;
    position: absolute;
    border: 8px solid transparent;
    border-bottom-color: #FFF;
    right: 16px;
    top: -16px; }
  .lg-outer .lg-dropdown > li:last-child {
    margin-bottom: 0px; }
  .lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon {
    color: #333; }
  .lg-outer .lg-dropdown a {
    color: #333;
    display: block;
    white-space: pre;
    padding: 4px 12px;
    font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px; }
    .lg-outer .lg-dropdown a:hover {
      background-color: rgba(0, 0, 0, 0.07); }
  .lg-outer .lg-dropdown .lg-dropdown-text {
    display: inline-block;
    line-height: 1;
    margin-top: -3px;
    vertical-align: middle; }
  .lg-outer .lg-dropdown .lg-icon {
    color: #333;
    display: inline-block;
    float: none;
    font-size: 20px;
    height: auto;
    line-height: 1;
    margin-right: 8px;
    padding: 0;
    vertical-align: middle;
    width: auto; }

.lg-outer #lg-share {
  position: relative; }
  .lg-outer #lg-share:after {
    content: "\E80D"; }

.lg-outer #lg-share-facebook .lg-icon {
  color: #3b5998; }
  .lg-outer #lg-share-facebook .lg-icon:after {
    content: "\E901"; }

.lg-outer #lg-share-twitter .lg-icon {
  color: #00aced; }
  .lg-outer #lg-share-twitter .lg-icon:after {
    content: "\E904"; }

.lg-outer #lg-share-googleplus .lg-icon {
  color: #dd4b39; }
  .lg-outer #lg-share-googleplus .lg-icon:after {
    content: "\E902"; }

.lg-outer #lg-share-pinterest .lg-icon {
  color: #cb2027; }
  .lg-outer #lg-share-pinterest .lg-icon:after {
    content: "\E903"; }

.lg-group:after {
  content: "";
  display: table;
  clear: both; }

.lg-outer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  text-align: left;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .lg-outer.lg-visible {
    opacity: 1; }
  .lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important; }
  .lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1; }
  .lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab; }
  .lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing; }
  .lg-outer .lg {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    max-height: 100%; }
  .lg-outer .lg-inner {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap; }
  .lg-outer .lg-item {
    background: url("/static/images/lightgallery/loading.gif") no-repeat scroll center center transparent;
    display: none !important; }
  .lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important; }
  .lg-outer.lg-css .lg-current {
    display: inline-block !important; }
  .lg-outer .lg-item, .lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%; }
    .lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }
  .lg-outer .lg-img-wrap {
    position: absolute;
    padding: 0 5px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .lg-outer .lg-item.lg-complete {
    background-image: none; }
  .lg-outer .lg-item.lg-current {
    z-index: 1060; }
  .lg-outer .lg-image {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important; }
  .lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
    opacity: 0;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s; }
  .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
    opacity: 1; }
  .lg-outer .lg-empty-html {
    display: none; }
  .lg-outer.lg-hide-download #lg-download {
    display: none; }

.lg-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease 0s;
  -o-transition: opacity 0.15s ease 0s;
  transition: opacity 0.15s ease 0s; }
  .lg-backdrop.in {
    opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  -webkit-transition: none 0s ease 0s !important;
  -moz-transition: none 0s ease 0s !important;
  -o-transition: none 0s ease 0s !important;
  transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item {
  opacity: 0; }
  .lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1; }
  .lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0); }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item {
  opacity: 0;
  position: absolute;
  left: 0; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
    left: -100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
    left: 100%; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    left: 0;
    opacity: 1; }
  .lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
    -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.sidr {
  display: block;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: hidden;
  overflow-y: auto; }
  .sidr.right {
    left: auto;
    right: -260px; }
  .sidr.left {
    left: -260px;
    right: auto; }

@font-face {
  font-family: 'SketchBlockLight';
  src: url(/static/fonts/38FDAD_0_0.b668d636.eot);
  src: url(/static/fonts/38FDAD_0_0.b668d636.eot?#iefix) format("embedded-opentype"), url(/static/fonts/38FDAD_0_0.0f24de21.woff2) format("woff2"), url(/static/fonts/38FDAD_0_0.2c8065c1.woff) format("woff"), url(/static/fonts/38FDAD_0_0.5199601f.ttf) format("truetype"); }

@font-face {
  font-family: 'SketchBlockBold';
  src: url(/static/fonts/38FDAD_1_0.c95666ea.eot);
  src: url(/static/fonts/38FDAD_1_0.c95666ea.eot?#iefix) format("embedded-opentype"), url(/static/fonts/38FDAD_1_0.b432fba7.woff2) format("woff2"), url(/static/fonts/38FDAD_1_0.1b0ca56b.woff) format("woff"), url(/static/fonts/38FDAD_1_0.89ec7773.ttf) format("truetype"); }

.content-wrapper {
  z-index: 0; }

.textBlock, .footer {
  font-family: "SketchBlockBold", sans-serif;
  padding-top: 20px;
  color: #003a5d;
  padding-bottom: 40px; }

.textBlock {
  margin-bottom: 0;
  text-align: center; }
  .textBlock.larger-font {
    padding-top: 0;
    font-size: 1.5em; }

.footer a {
  color: #003a5d; }

.footer-logo {
  margin: 50px auto;
  display: flex;
  justify-content: center;
  max-width: 400px;
  height: auto; }
  @media (min-width: 992px) {
    .footer-logo {
      margin: 100px auto; } }
  .footer-logo img {
    height: 100%;
    max-width: 100%; }

.menu-padder {
  padding-top: 250px; }

.bg-container-light-green {
  background-color: rgba(112, 185, 96, 0.1);
  padding-bottom: 50px; }

.bg-container-dark-blue {
  background-color: #003a5d; }
  .bg-container-dark-blue .textBlock {
    font-family: "SketchBlockBold", sans-serif;
    padding-top: 40px;
    color: white; }

.toolbox-headline {
  display: flex;
  justify-content: center;
  align-content: center; }

h1, h2, h3, h4, h5 {
  font-family: "SketchBlockBold", sans-serif;
  transform-origin: top left;
  transform: rotateY(-9deg) skewY(-9deg);
  box-sizing: content-box;
  max-height: 1000px; }

h2 {
  max-width: 908px;
  margin: 0 auto; }

.heading {
  padding-top: 50px;
  max-width: 100%;
  position: relative;
  color: #003a5d;
  text-align: center;
  border: 1px solid transparent; }
  @media (min-width: 992px) {
    .heading {
      padding-top: 100px; } }
  .heading.image {
    display: flex;
    justify-content: center;
    overflow: hidden; }
  .heading img {
    margin-bottom: 50px;
    flex: 1 0 auto; }
    @media (min-width: 992px) {
      .heading img {
        margin-bottom: 80px; } }

.green {
  color: #70b960; }

.blue {
  color: #003a5d; }

.switzerland_container {
  display: flex;
  justify-content: center;
  position: relative; }
  @media (min-width: 992px) {
    .switzerland_container {
      max-width: 80%;
      margin: 0 auto; } }
  .switzerland_container .regions {
    position: relative; }

.toolbox-spacer span.spacer-default {
  height: 40px; }

.toolbox-spacer span.spacer-large {
  height: 80px; }

.toolbox-spacer span.spacer-x-large {
  height: 120px; }

div.result {
  position: absolute;
  font-family: "SketchBlockBold", sans-serif;
  border-radius: 50%;
  background: #003a5d;
  color: #fff;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center; }
  div.result.size-0 {
    visibility: hidden; }
  div.result:before {
    content: "";
    display: block;
    padding-top: 100%;
    /* initial ratio of 1:1*/ }
  div.result.size-0 {
    transition: all 0ms linear;
    width: calc((10% + 0 * 0.3px) * 1);
    font-size: calc(16px + 0px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 0 * 0.3px) * -0.5);
    margin-top: calc((10% + 0 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-0 {
        font-size: calc(16px + 0px + 16px); } }
  div.result.size-1 {
    transition: all 15ms linear;
    width: calc((10% + 1 * 0.3px) * 1);
    font-size: calc(16px + 0.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 1 * 0.3px) * -0.5);
    margin-top: calc((10% + 1 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-1 {
        font-size: calc(16px + 0.1px + 16px); } }
  div.result.size-2 {
    transition: all 30ms linear;
    width: calc((10% + 2 * 0.3px) * 1);
    font-size: calc(16px + 0.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 2 * 0.3px) * -0.5);
    margin-top: calc((10% + 2 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-2 {
        font-size: calc(16px + 0.2px + 16px); } }
  div.result.size-3 {
    transition: all 45ms linear;
    width: calc((10% + 3 * 0.3px) * 1);
    font-size: calc(16px + 0.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 3 * 0.3px) * -0.5);
    margin-top: calc((10% + 3 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-3 {
        font-size: calc(16px + 0.3px + 16px); } }
  div.result.size-4 {
    transition: all 60ms linear;
    width: calc((10% + 4 * 0.3px) * 1);
    font-size: calc(16px + 0.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 4 * 0.3px) * -0.5);
    margin-top: calc((10% + 4 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-4 {
        font-size: calc(16px + 0.4px + 16px); } }
  div.result.size-5 {
    transition: all 75ms linear;
    width: calc((10% + 5 * 0.3px) * 1);
    font-size: calc(16px + 0.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 5 * 0.3px) * -0.5);
    margin-top: calc((10% + 5 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-5 {
        font-size: calc(16px + 0.5px + 16px); } }
  div.result.size-6 {
    transition: all 90ms linear;
    width: calc((10% + 6 * 0.3px) * 1);
    font-size: calc(16px + 0.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 6 * 0.3px) * -0.5);
    margin-top: calc((10% + 6 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-6 {
        font-size: calc(16px + 0.6px + 16px); } }
  div.result.size-7 {
    transition: all 105ms linear;
    width: calc((10% + 7 * 0.3px) * 1);
    font-size: calc(16px + 0.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 7 * 0.3px) * -0.5);
    margin-top: calc((10% + 7 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-7 {
        font-size: calc(16px + 0.7px + 16px); } }
  div.result.size-8 {
    transition: all 120ms linear;
    width: calc((10% + 8 * 0.3px) * 1);
    font-size: calc(16px + 0.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 8 * 0.3px) * -0.5);
    margin-top: calc((10% + 8 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-8 {
        font-size: calc(16px + 0.8px + 16px); } }
  div.result.size-9 {
    transition: all 135ms linear;
    width: calc((10% + 9 * 0.3px) * 1);
    font-size: calc(16px + 0.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 9 * 0.3px) * -0.5);
    margin-top: calc((10% + 9 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-9 {
        font-size: calc(16px + 0.9px + 16px); } }
  div.result.size-10 {
    transition: all 150ms linear;
    width: calc((10% + 10 * 0.3px) * 1);
    font-size: calc(16px + 1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 10 * 0.3px) * -0.5);
    margin-top: calc((10% + 10 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-10 {
        font-size: calc(16px + 1px + 16px); } }
  div.result.size-11 {
    transition: all 165ms linear;
    width: calc((10% + 11 * 0.3px) * 1);
    font-size: calc(16px + 1.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 11 * 0.3px) * -0.5);
    margin-top: calc((10% + 11 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-11 {
        font-size: calc(16px + 1.1px + 16px); } }
  div.result.size-12 {
    transition: all 180ms linear;
    width: calc((10% + 12 * 0.3px) * 1);
    font-size: calc(16px + 1.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 12 * 0.3px) * -0.5);
    margin-top: calc((10% + 12 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-12 {
        font-size: calc(16px + 1.2px + 16px); } }
  div.result.size-13 {
    transition: all 195ms linear;
    width: calc((10% + 13 * 0.3px) * 1);
    font-size: calc(16px + 1.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 13 * 0.3px) * -0.5);
    margin-top: calc((10% + 13 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-13 {
        font-size: calc(16px + 1.3px + 16px); } }
  div.result.size-14 {
    transition: all 210ms linear;
    width: calc((10% + 14 * 0.3px) * 1);
    font-size: calc(16px + 1.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 14 * 0.3px) * -0.5);
    margin-top: calc((10% + 14 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-14 {
        font-size: calc(16px + 1.4px + 16px); } }
  div.result.size-15 {
    transition: all 225ms linear;
    width: calc((10% + 15 * 0.3px) * 1);
    font-size: calc(16px + 1.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 15 * 0.3px) * -0.5);
    margin-top: calc((10% + 15 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-15 {
        font-size: calc(16px + 1.5px + 16px); } }
  div.result.size-16 {
    transition: all 240ms linear;
    width: calc((10% + 16 * 0.3px) * 1);
    font-size: calc(16px + 1.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 16 * 0.3px) * -0.5);
    margin-top: calc((10% + 16 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-16 {
        font-size: calc(16px + 1.6px + 16px); } }
  div.result.size-17 {
    transition: all 255ms linear;
    width: calc((10% + 17 * 0.3px) * 1);
    font-size: calc(16px + 1.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 17 * 0.3px) * -0.5);
    margin-top: calc((10% + 17 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-17 {
        font-size: calc(16px + 1.7px + 16px); } }
  div.result.size-18 {
    transition: all 270ms linear;
    width: calc((10% + 18 * 0.3px) * 1);
    font-size: calc(16px + 1.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 18 * 0.3px) * -0.5);
    margin-top: calc((10% + 18 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-18 {
        font-size: calc(16px + 1.8px + 16px); } }
  div.result.size-19 {
    transition: all 285ms linear;
    width: calc((10% + 19 * 0.3px) * 1);
    font-size: calc(16px + 1.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 19 * 0.3px) * -0.5);
    margin-top: calc((10% + 19 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-19 {
        font-size: calc(16px + 1.9px + 16px); } }
  div.result.size-20 {
    transition: all 300ms linear;
    width: calc((10% + 20 * 0.3px) * 1);
    font-size: calc(16px + 2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 20 * 0.3px) * -0.5);
    margin-top: calc((10% + 20 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-20 {
        font-size: calc(16px + 2px + 16px); } }
  div.result.size-21 {
    transition: all 315ms linear;
    width: calc((10% + 21 * 0.3px) * 1);
    font-size: calc(16px + 2.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 21 * 0.3px) * -0.5);
    margin-top: calc((10% + 21 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-21 {
        font-size: calc(16px + 2.1px + 16px); } }
  div.result.size-22 {
    transition: all 330ms linear;
    width: calc((10% + 22 * 0.3px) * 1);
    font-size: calc(16px + 2.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 22 * 0.3px) * -0.5);
    margin-top: calc((10% + 22 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-22 {
        font-size: calc(16px + 2.2px + 16px); } }
  div.result.size-23 {
    transition: all 345ms linear;
    width: calc((10% + 23 * 0.3px) * 1);
    font-size: calc(16px + 2.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 23 * 0.3px) * -0.5);
    margin-top: calc((10% + 23 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-23 {
        font-size: calc(16px + 2.3px + 16px); } }
  div.result.size-24 {
    transition: all 360ms linear;
    width: calc((10% + 24 * 0.3px) * 1);
    font-size: calc(16px + 2.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 24 * 0.3px) * -0.5);
    margin-top: calc((10% + 24 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-24 {
        font-size: calc(16px + 2.4px + 16px); } }
  div.result.size-25 {
    transition: all 375ms linear;
    width: calc((10% + 25 * 0.3px) * 1);
    font-size: calc(16px + 2.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 25 * 0.3px) * -0.5);
    margin-top: calc((10% + 25 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-25 {
        font-size: calc(16px + 2.5px + 16px); } }
  div.result.size-26 {
    transition: all 390ms linear;
    width: calc((10% + 26 * 0.3px) * 1);
    font-size: calc(16px + 2.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 26 * 0.3px) * -0.5);
    margin-top: calc((10% + 26 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-26 {
        font-size: calc(16px + 2.6px + 16px); } }
  div.result.size-27 {
    transition: all 405ms linear;
    width: calc((10% + 27 * 0.3px) * 1);
    font-size: calc(16px + 2.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 27 * 0.3px) * -0.5);
    margin-top: calc((10% + 27 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-27 {
        font-size: calc(16px + 2.7px + 16px); } }
  div.result.size-28 {
    transition: all 420ms linear;
    width: calc((10% + 28 * 0.3px) * 1);
    font-size: calc(16px + 2.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 28 * 0.3px) * -0.5);
    margin-top: calc((10% + 28 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-28 {
        font-size: calc(16px + 2.8px + 16px); } }
  div.result.size-29 {
    transition: all 435ms linear;
    width: calc((10% + 29 * 0.3px) * 1);
    font-size: calc(16px + 2.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 29 * 0.3px) * -0.5);
    margin-top: calc((10% + 29 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-29 {
        font-size: calc(16px + 2.9px + 16px); } }
  div.result.size-30 {
    transition: all 450ms linear;
    width: calc((10% + 30 * 0.3px) * 1);
    font-size: calc(16px + 3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 30 * 0.3px) * -0.5);
    margin-top: calc((10% + 30 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-30 {
        font-size: calc(16px + 3px + 16px); } }
  div.result.size-31 {
    transition: all 465ms linear;
    width: calc((10% + 31 * 0.3px) * 1);
    font-size: calc(16px + 3.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 31 * 0.3px) * -0.5);
    margin-top: calc((10% + 31 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-31 {
        font-size: calc(16px + 3.1px + 16px); } }
  div.result.size-32 {
    transition: all 480ms linear;
    width: calc((10% + 32 * 0.3px) * 1);
    font-size: calc(16px + 3.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 32 * 0.3px) * -0.5);
    margin-top: calc((10% + 32 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-32 {
        font-size: calc(16px + 3.2px + 16px); } }
  div.result.size-33 {
    transition: all 495ms linear;
    width: calc((10% + 33 * 0.3px) * 1);
    font-size: calc(16px + 3.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 33 * 0.3px) * -0.5);
    margin-top: calc((10% + 33 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-33 {
        font-size: calc(16px + 3.3px + 16px); } }
  div.result.size-34 {
    transition: all 510ms linear;
    width: calc((10% + 34 * 0.3px) * 1);
    font-size: calc(16px + 3.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 34 * 0.3px) * -0.5);
    margin-top: calc((10% + 34 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-34 {
        font-size: calc(16px + 3.4px + 16px); } }
  div.result.size-35 {
    transition: all 525ms linear;
    width: calc((10% + 35 * 0.3px) * 1);
    font-size: calc(16px + 3.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 35 * 0.3px) * -0.5);
    margin-top: calc((10% + 35 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-35 {
        font-size: calc(16px + 3.5px + 16px); } }
  div.result.size-36 {
    transition: all 540ms linear;
    width: calc((10% + 36 * 0.3px) * 1);
    font-size: calc(16px + 3.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 36 * 0.3px) * -0.5);
    margin-top: calc((10% + 36 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-36 {
        font-size: calc(16px + 3.6px + 16px); } }
  div.result.size-37 {
    transition: all 555ms linear;
    width: calc((10% + 37 * 0.3px) * 1);
    font-size: calc(16px + 3.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 37 * 0.3px) * -0.5);
    margin-top: calc((10% + 37 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-37 {
        font-size: calc(16px + 3.7px + 16px); } }
  div.result.size-38 {
    transition: all 570ms linear;
    width: calc((10% + 38 * 0.3px) * 1);
    font-size: calc(16px + 3.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 38 * 0.3px) * -0.5);
    margin-top: calc((10% + 38 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-38 {
        font-size: calc(16px + 3.8px + 16px); } }
  div.result.size-39 {
    transition: all 585ms linear;
    width: calc((10% + 39 * 0.3px) * 1);
    font-size: calc(16px + 3.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 39 * 0.3px) * -0.5);
    margin-top: calc((10% + 39 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-39 {
        font-size: calc(16px + 3.9px + 16px); } }
  div.result.size-40 {
    transition: all 600ms linear;
    width: calc((10% + 40 * 0.3px) * 1);
    font-size: calc(16px + 4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 40 * 0.3px) * -0.5);
    margin-top: calc((10% + 40 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-40 {
        font-size: calc(16px + 4px + 16px); } }
  div.result.size-41 {
    transition: all 615ms linear;
    width: calc((10% + 41 * 0.3px) * 1);
    font-size: calc(16px + 4.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 41 * 0.3px) * -0.5);
    margin-top: calc((10% + 41 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-41 {
        font-size: calc(16px + 4.1px + 16px); } }
  div.result.size-42 {
    transition: all 630ms linear;
    width: calc((10% + 42 * 0.3px) * 1);
    font-size: calc(16px + 4.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 42 * 0.3px) * -0.5);
    margin-top: calc((10% + 42 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-42 {
        font-size: calc(16px + 4.2px + 16px); } }
  div.result.size-43 {
    transition: all 645ms linear;
    width: calc((10% + 43 * 0.3px) * 1);
    font-size: calc(16px + 4.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 43 * 0.3px) * -0.5);
    margin-top: calc((10% + 43 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-43 {
        font-size: calc(16px + 4.3px + 16px); } }
  div.result.size-44 {
    transition: all 660ms linear;
    width: calc((10% + 44 * 0.3px) * 1);
    font-size: calc(16px + 4.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 44 * 0.3px) * -0.5);
    margin-top: calc((10% + 44 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-44 {
        font-size: calc(16px + 4.4px + 16px); } }
  div.result.size-45 {
    transition: all 675ms linear;
    width: calc((10% + 45 * 0.3px) * 1);
    font-size: calc(16px + 4.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 45 * 0.3px) * -0.5);
    margin-top: calc((10% + 45 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-45 {
        font-size: calc(16px + 4.5px + 16px); } }
  div.result.size-46 {
    transition: all 690ms linear;
    width: calc((10% + 46 * 0.3px) * 1);
    font-size: calc(16px + 4.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 46 * 0.3px) * -0.5);
    margin-top: calc((10% + 46 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-46 {
        font-size: calc(16px + 4.6px + 16px); } }
  div.result.size-47 {
    transition: all 705ms linear;
    width: calc((10% + 47 * 0.3px) * 1);
    font-size: calc(16px + 4.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 47 * 0.3px) * -0.5);
    margin-top: calc((10% + 47 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-47 {
        font-size: calc(16px + 4.7px + 16px); } }
  div.result.size-48 {
    transition: all 720ms linear;
    width: calc((10% + 48 * 0.3px) * 1);
    font-size: calc(16px + 4.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 48 * 0.3px) * -0.5);
    margin-top: calc((10% + 48 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-48 {
        font-size: calc(16px + 4.8px + 16px); } }
  div.result.size-49 {
    transition: all 735ms linear;
    width: calc((10% + 49 * 0.3px) * 1);
    font-size: calc(16px + 4.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 49 * 0.3px) * -0.5);
    margin-top: calc((10% + 49 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-49 {
        font-size: calc(16px + 4.9px + 16px); } }
  div.result.size-50 {
    transition: all 750ms linear;
    width: calc((10% + 50 * 0.3px) * 1);
    font-size: calc(16px + 5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 50 * 0.3px) * -0.5);
    margin-top: calc((10% + 50 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-50 {
        font-size: calc(16px + 5px + 16px); } }
  div.result.size-51 {
    transition: all 765ms linear;
    width: calc((10% + 51 * 0.3px) * 1);
    font-size: calc(16px + 5.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 51 * 0.3px) * -0.5);
    margin-top: calc((10% + 51 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-51 {
        font-size: calc(16px + 5.1px + 16px); } }
  div.result.size-52 {
    transition: all 780ms linear;
    width: calc((10% + 52 * 0.3px) * 1);
    font-size: calc(16px + 5.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 52 * 0.3px) * -0.5);
    margin-top: calc((10% + 52 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-52 {
        font-size: calc(16px + 5.2px + 16px); } }
  div.result.size-53 {
    transition: all 795ms linear;
    width: calc((10% + 53 * 0.3px) * 1);
    font-size: calc(16px + 5.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 53 * 0.3px) * -0.5);
    margin-top: calc((10% + 53 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-53 {
        font-size: calc(16px + 5.3px + 16px); } }
  div.result.size-54 {
    transition: all 810ms linear;
    width: calc((10% + 54 * 0.3px) * 1);
    font-size: calc(16px + 5.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 54 * 0.3px) * -0.5);
    margin-top: calc((10% + 54 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-54 {
        font-size: calc(16px + 5.4px + 16px); } }
  div.result.size-55 {
    transition: all 825ms linear;
    width: calc((10% + 55 * 0.3px) * 1);
    font-size: calc(16px + 5.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 55 * 0.3px) * -0.5);
    margin-top: calc((10% + 55 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-55 {
        font-size: calc(16px + 5.5px + 16px); } }
  div.result.size-56 {
    transition: all 840ms linear;
    width: calc((10% + 56 * 0.3px) * 1);
    font-size: calc(16px + 5.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 56 * 0.3px) * -0.5);
    margin-top: calc((10% + 56 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-56 {
        font-size: calc(16px + 5.6px + 16px); } }
  div.result.size-57 {
    transition: all 855ms linear;
    width: calc((10% + 57 * 0.3px) * 1);
    font-size: calc(16px + 5.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 57 * 0.3px) * -0.5);
    margin-top: calc((10% + 57 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-57 {
        font-size: calc(16px + 5.7px + 16px); } }
  div.result.size-58 {
    transition: all 870ms linear;
    width: calc((10% + 58 * 0.3px) * 1);
    font-size: calc(16px + 5.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 58 * 0.3px) * -0.5);
    margin-top: calc((10% + 58 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-58 {
        font-size: calc(16px + 5.8px + 16px); } }
  div.result.size-59 {
    transition: all 885ms linear;
    width: calc((10% + 59 * 0.3px) * 1);
    font-size: calc(16px + 5.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 59 * 0.3px) * -0.5);
    margin-top: calc((10% + 59 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-59 {
        font-size: calc(16px + 5.9px + 16px); } }
  div.result.size-60 {
    transition: all 900ms linear;
    width: calc((10% + 60 * 0.3px) * 1);
    font-size: calc(16px + 6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 60 * 0.3px) * -0.5);
    margin-top: calc((10% + 60 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-60 {
        font-size: calc(16px + 6px + 16px); } }
  div.result.size-61 {
    transition: all 915ms linear;
    width: calc((10% + 61 * 0.3px) * 1);
    font-size: calc(16px + 6.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 61 * 0.3px) * -0.5);
    margin-top: calc((10% + 61 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-61 {
        font-size: calc(16px + 6.1px + 16px); } }
  div.result.size-62 {
    transition: all 930ms linear;
    width: calc((10% + 62 * 0.3px) * 1);
    font-size: calc(16px + 6.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 62 * 0.3px) * -0.5);
    margin-top: calc((10% + 62 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-62 {
        font-size: calc(16px + 6.2px + 16px); } }
  div.result.size-63 {
    transition: all 945ms linear;
    width: calc((10% + 63 * 0.3px) * 1);
    font-size: calc(16px + 6.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 63 * 0.3px) * -0.5);
    margin-top: calc((10% + 63 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-63 {
        font-size: calc(16px + 6.3px + 16px); } }
  div.result.size-64 {
    transition: all 960ms linear;
    width: calc((10% + 64 * 0.3px) * 1);
    font-size: calc(16px + 6.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 64 * 0.3px) * -0.5);
    margin-top: calc((10% + 64 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-64 {
        font-size: calc(16px + 6.4px + 16px); } }
  div.result.size-65 {
    transition: all 975ms linear;
    width: calc((10% + 65 * 0.3px) * 1);
    font-size: calc(16px + 6.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 65 * 0.3px) * -0.5);
    margin-top: calc((10% + 65 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-65 {
        font-size: calc(16px + 6.5px + 16px); } }
  div.result.size-66 {
    transition: all 990ms linear;
    width: calc((10% + 66 * 0.3px) * 1);
    font-size: calc(16px + 6.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 66 * 0.3px) * -0.5);
    margin-top: calc((10% + 66 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-66 {
        font-size: calc(16px + 6.6px + 16px); } }
  div.result.size-67 {
    transition: all 1005ms linear;
    width: calc((10% + 67 * 0.3px) * 1);
    font-size: calc(16px + 6.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 67 * 0.3px) * -0.5);
    margin-top: calc((10% + 67 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-67 {
        font-size: calc(16px + 6.7px + 16px); } }
  div.result.size-68 {
    transition: all 1020ms linear;
    width: calc((10% + 68 * 0.3px) * 1);
    font-size: calc(16px + 6.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 68 * 0.3px) * -0.5);
    margin-top: calc((10% + 68 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-68 {
        font-size: calc(16px + 6.8px + 16px); } }
  div.result.size-69 {
    transition: all 1035ms linear;
    width: calc((10% + 69 * 0.3px) * 1);
    font-size: calc(16px + 6.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 69 * 0.3px) * -0.5);
    margin-top: calc((10% + 69 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-69 {
        font-size: calc(16px + 6.9px + 16px); } }
  div.result.size-70 {
    transition: all 1050ms linear;
    width: calc((10% + 70 * 0.3px) * 1);
    font-size: calc(16px + 7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 70 * 0.3px) * -0.5);
    margin-top: calc((10% + 70 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-70 {
        font-size: calc(16px + 7px + 16px); } }
  div.result.size-71 {
    transition: all 1065ms linear;
    width: calc((10% + 71 * 0.3px) * 1);
    font-size: calc(16px + 7.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 71 * 0.3px) * -0.5);
    margin-top: calc((10% + 71 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-71 {
        font-size: calc(16px + 7.1px + 16px); } }
  div.result.size-72 {
    transition: all 1080ms linear;
    width: calc((10% + 72 * 0.3px) * 1);
    font-size: calc(16px + 7.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 72 * 0.3px) * -0.5);
    margin-top: calc((10% + 72 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-72 {
        font-size: calc(16px + 7.2px + 16px); } }
  div.result.size-73 {
    transition: all 1095ms linear;
    width: calc((10% + 73 * 0.3px) * 1);
    font-size: calc(16px + 7.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 73 * 0.3px) * -0.5);
    margin-top: calc((10% + 73 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-73 {
        font-size: calc(16px + 7.3px + 16px); } }
  div.result.size-74 {
    transition: all 1110ms linear;
    width: calc((10% + 74 * 0.3px) * 1);
    font-size: calc(16px + 7.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 74 * 0.3px) * -0.5);
    margin-top: calc((10% + 74 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-74 {
        font-size: calc(16px + 7.4px + 16px); } }
  div.result.size-75 {
    transition: all 1125ms linear;
    width: calc((10% + 75 * 0.3px) * 1);
    font-size: calc(16px + 7.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 75 * 0.3px) * -0.5);
    margin-top: calc((10% + 75 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-75 {
        font-size: calc(16px + 7.5px + 16px); } }
  div.result.size-76 {
    transition: all 1140ms linear;
    width: calc((10% + 76 * 0.3px) * 1);
    font-size: calc(16px + 7.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 76 * 0.3px) * -0.5);
    margin-top: calc((10% + 76 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-76 {
        font-size: calc(16px + 7.6px + 16px); } }
  div.result.size-77 {
    transition: all 1155ms linear;
    width: calc((10% + 77 * 0.3px) * 1);
    font-size: calc(16px + 7.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 77 * 0.3px) * -0.5);
    margin-top: calc((10% + 77 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-77 {
        font-size: calc(16px + 7.7px + 16px); } }
  div.result.size-78 {
    transition: all 1170ms linear;
    width: calc((10% + 78 * 0.3px) * 1);
    font-size: calc(16px + 7.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 78 * 0.3px) * -0.5);
    margin-top: calc((10% + 78 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-78 {
        font-size: calc(16px + 7.8px + 16px); } }
  div.result.size-79 {
    transition: all 1185ms linear;
    width: calc((10% + 79 * 0.3px) * 1);
    font-size: calc(16px + 7.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 79 * 0.3px) * -0.5);
    margin-top: calc((10% + 79 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-79 {
        font-size: calc(16px + 7.9px + 16px); } }
  div.result.size-80 {
    transition: all 1200ms linear;
    width: calc((10% + 80 * 0.3px) * 1);
    font-size: calc(16px + 8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 80 * 0.3px) * -0.5);
    margin-top: calc((10% + 80 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-80 {
        font-size: calc(16px + 8px + 16px); } }
  div.result.size-81 {
    transition: all 1215ms linear;
    width: calc((10% + 81 * 0.3px) * 1);
    font-size: calc(16px + 8.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 81 * 0.3px) * -0.5);
    margin-top: calc((10% + 81 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-81 {
        font-size: calc(16px + 8.1px + 16px); } }
  div.result.size-82 {
    transition: all 1230ms linear;
    width: calc((10% + 82 * 0.3px) * 1);
    font-size: calc(16px + 8.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 82 * 0.3px) * -0.5);
    margin-top: calc((10% + 82 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-82 {
        font-size: calc(16px + 8.2px + 16px); } }
  div.result.size-83 {
    transition: all 1245ms linear;
    width: calc((10% + 83 * 0.3px) * 1);
    font-size: calc(16px + 8.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 83 * 0.3px) * -0.5);
    margin-top: calc((10% + 83 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-83 {
        font-size: calc(16px + 8.3px + 16px); } }
  div.result.size-84 {
    transition: all 1260ms linear;
    width: calc((10% + 84 * 0.3px) * 1);
    font-size: calc(16px + 8.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 84 * 0.3px) * -0.5);
    margin-top: calc((10% + 84 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-84 {
        font-size: calc(16px + 8.4px + 16px); } }
  div.result.size-85 {
    transition: all 1275ms linear;
    width: calc((10% + 85 * 0.3px) * 1);
    font-size: calc(16px + 8.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 85 * 0.3px) * -0.5);
    margin-top: calc((10% + 85 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-85 {
        font-size: calc(16px + 8.5px + 16px); } }
  div.result.size-86 {
    transition: all 1290ms linear;
    width: calc((10% + 86 * 0.3px) * 1);
    font-size: calc(16px + 8.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 86 * 0.3px) * -0.5);
    margin-top: calc((10% + 86 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-86 {
        font-size: calc(16px + 8.6px + 16px); } }
  div.result.size-87 {
    transition: all 1305ms linear;
    width: calc((10% + 87 * 0.3px) * 1);
    font-size: calc(16px + 8.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 87 * 0.3px) * -0.5);
    margin-top: calc((10% + 87 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-87 {
        font-size: calc(16px + 8.7px + 16px); } }
  div.result.size-88 {
    transition: all 1320ms linear;
    width: calc((10% + 88 * 0.3px) * 1);
    font-size: calc(16px + 8.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 88 * 0.3px) * -0.5);
    margin-top: calc((10% + 88 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-88 {
        font-size: calc(16px + 8.8px + 16px); } }
  div.result.size-89 {
    transition: all 1335ms linear;
    width: calc((10% + 89 * 0.3px) * 1);
    font-size: calc(16px + 8.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 89 * 0.3px) * -0.5);
    margin-top: calc((10% + 89 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-89 {
        font-size: calc(16px + 8.9px + 16px); } }
  div.result.size-90 {
    transition: all 1350ms linear;
    width: calc((10% + 90 * 0.3px) * 1);
    font-size: calc(16px + 9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 90 * 0.3px) * -0.5);
    margin-top: calc((10% + 90 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-90 {
        font-size: calc(16px + 9px + 16px); } }
  div.result.size-91 {
    transition: all 1365ms linear;
    width: calc((10% + 91 * 0.3px) * 1);
    font-size: calc(16px + 9.1px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 91 * 0.3px) * -0.5);
    margin-top: calc((10% + 91 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-91 {
        font-size: calc(16px + 9.1px + 16px); } }
  div.result.size-92 {
    transition: all 1380ms linear;
    width: calc((10% + 92 * 0.3px) * 1);
    font-size: calc(16px + 9.2px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 92 * 0.3px) * -0.5);
    margin-top: calc((10% + 92 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-92 {
        font-size: calc(16px + 9.2px + 16px); } }
  div.result.size-93 {
    transition: all 1395ms linear;
    width: calc((10% + 93 * 0.3px) * 1);
    font-size: calc(16px + 9.3px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 93 * 0.3px) * -0.5);
    margin-top: calc((10% + 93 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-93 {
        font-size: calc(16px + 9.3px + 16px); } }
  div.result.size-94 {
    transition: all 1410ms linear;
    width: calc((10% + 94 * 0.3px) * 1);
    font-size: calc(16px + 9.4px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 94 * 0.3px) * -0.5);
    margin-top: calc((10% + 94 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-94 {
        font-size: calc(16px + 9.4px + 16px); } }
  div.result.size-95 {
    transition: all 1425ms linear;
    width: calc((10% + 95 * 0.3px) * 1);
    font-size: calc(16px + 9.5px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 95 * 0.3px) * -0.5);
    margin-top: calc((10% + 95 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-95 {
        font-size: calc(16px + 9.5px + 16px); } }
  div.result.size-96 {
    transition: all 1440ms linear;
    width: calc((10% + 96 * 0.3px) * 1);
    font-size: calc(16px + 9.6px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 96 * 0.3px) * -0.5);
    margin-top: calc((10% + 96 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-96 {
        font-size: calc(16px + 9.6px + 16px); } }
  div.result.size-97 {
    transition: all 1455ms linear;
    width: calc((10% + 97 * 0.3px) * 1);
    font-size: calc(16px + 9.7px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 97 * 0.3px) * -0.5);
    margin-top: calc((10% + 97 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-97 {
        font-size: calc(16px + 9.7px + 16px); } }
  div.result.size-98 {
    transition: all 1470ms linear;
    width: calc((10% + 98 * 0.3px) * 1);
    font-size: calc(16px + 9.8px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 98 * 0.3px) * -0.5);
    margin-top: calc((10% + 98 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-98 {
        font-size: calc(16px + 9.8px + 16px); } }
  div.result.size-99 {
    transition: all 1485ms linear;
    width: calc((10% + 99 * 0.3px) * 1);
    font-size: calc(16px + 9.9px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 99 * 0.3px) * -0.5);
    margin-top: calc((10% + 99 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-99 {
        font-size: calc(16px + 9.9px + 16px); } }
  div.result.size-100 {
    transition: all 1500ms linear;
    width: calc((10% + 100 * 0.3px) * 1);
    font-size: calc(16px + 10px + 1vw);
    line-height: 1;
    margin-right: calc((10% + 100 * 0.3px) * -0.5);
    margin-top: calc((10% + 100 * 0.3px) * -0.5); }
    @media (min-width: 1600px) {
      div.result.size-100 {
        font-size: calc(16px + 10px + 16px); } }
  div.result.rheintal {
    right: 23%;
    top: 25%; }
  div.result.zuerich {
    right: 47%;
    top: 27%; }
  div.result.tessin {
    top: 70%;
    right: 36%; }
  div.result.basel {
    top: 19%;
    right: 64%; }
  div.result.wallis {
    top: 75%;
    right: 65%; }
  div.result.bern {
    top: 45%;
    right: 71%; }
  div.result.lausanne {
    top: 60%;
    right: 85%; }
  div.result.thurgau {
    right: 34%;
    top: 13%; }
  div.result.luzern {
    top: 49%;
    right: 52%; }
  div.result.grischa {
    right: 20%;
    top: 55%; }
  div.result.largest {
    background: #70b960;
    z-index: 2; }
  div.result div {
    transform: skewY(-9deg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3px;
    position: relative;
    padding-top: 3px; }

.resultText {
  font-family: "SketchBlockBold", sans-serif;
  text-align: center;
  padding: 50px 0;
  color: #003a5d;
  max-width: 720px;
  margin: 0 auto; }
  .resultText .title {
    font-size: 1.5em;
    color: #70b960; }
  .resultText.disclaimer {
    max-width: 450px;
    font-size: 0.75em;
    color: #70b960;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 100px;
    transition: font-size 0.5s 0.5s, max-width 0.5s 0.5s; }
    .resultText.disclaimer.warning {
      font-size: 2rem;
      max-width: 100%; }

.imageButton {
  background-color: transparent;
  z-index: 2;
  border: none;
  outline: none; }
  @media (hover) {
    .imageButton {
      opacity: 0.6; }
      .imageButton:hover, .imageButton:focus {
        border: none;
        outline: none;
        opacity: 1; } }

.mainlogo {
  margin-top: 80px;
  margin-bottom: 40px;
  transition: width 0.5s, max-width 0.5s;
  max-width: 300px;
  height: auto; }
  .mainlogo.preload {
    width: 40%;
    max-width: 100%; }

.textBlock.toc {
  font-family: Arial, sans-serif;
  max-height: 20px;
  overflow: hidden;
  font-size: 16px;
  display: block;
  transition: max-height 0.9s; }
  .textBlock.toc.opened {
    font-size: 12px;
    max-height: 999px; }

.submit-success, .submit-error {
  font-family: "SketchBlockBold", sans-serif; }

input, select, textarea, button {
  font-family: "SketchBlockBold", sans-serif; }

input[type=text], textarea[type=text] {
  color: #003a5d; }
  input[type=text]:focus, textarea[type=text]:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 58, 93, 0.25);
    border-color: #003a5d; }

.input-group {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .input-group input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
  .input-group .circle, .input-group .square {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 2px solid #003a5d;
    border-radius: 50%; }
    #wettbewerb_container .input-group .circle, #wettbewerb_container .input-group .square {
      border: 2px solid white; }
    .input-group .circle:after, .input-group .square:after {
      content: "";
      position: absolute;
      display: none; }
  .input-group .square {
    border-radius: 0; }
  @media (hover) {
    .input-group:hover input ~ .checkmark {
      display: block;
      opacity: 0.3; } }
  .input-group img {
    position: absolute;
    top: -5px;
    left: -5px;
    height: 35px;
    width: 35px;
    display: none; }
  .input-group input:checked ~ .checkmark {
    display: block;
    opacity: 1; }
  .input-group input:checked ~ .circle:after, .input-group input:checked ~ .square:after {
    display: block; }
  .input-group input.invalid ~ .circle, .input-group input.invalid ~ .square {
    border: 2px solid red; }
  .input-group .circle:after, .input-group .square:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%; }

.question {
  margin-bottom: 50px;
  font-family: "SketchBlockBold", sans-serif; }
  .question.question-1 {
    margin-top: 50px; }
  @media (min-width: 992px) {
    .question {
      margin-bottom: 100px; } }
  .question .answer {
    color: #70b960;
    flex: 0 1 20%;
    text-align: right;
    margin-right: 15px;
    margin-top: 2px; }
  .question .questionTitle {
    color: #70b960;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    margin-bottom: 10px; }
  .question .questionText {
    font-size: 1.5em;
    color: #003a5d;
    margin-bottom: 20px;
    text-align: center; }
    .question .questionText span {
      display: inline-block; }
  .question .answer-right {
    text-align: left;
    margin-left: 15px; }
  .question .answer_row {
    display: flex;
    align-content: space-between;
    justify-content: center; }
    .question .answer_row .slider {
      display: flex;
      justify-content: space-between;
      width: 400px; }
      .question .answer_row .slider .input-group {
        padding-left: 0;
        width: 25px;
        display: block; }
    @media (max-width: 767.98px) {
      .question .answer_row {
        flex-flow: wrap; }
        .question .answer_row .answer {
          flex: 1 0 auto;
          text-align: left;
          margin: 25px 0 0 0; }
        .question .answer_row .answer-right {
          order: 3;
          text-align: right; }
        .question .answer_row .answer-left {
          order: 2; }
        .question .answer_row .slider {
          order: 1;
          flex: 1 0 100%;
          display: flex;
          justify-content: space-between; } }

label {
  font-family: "SketchBlockBold", sans-serif;
  color: #70b960; }

html {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

form .form-check {
  display: flex;
  justify-content: center; }

header#header {
  position: fixed;
  width: 100%;
  height: 150px;
  background-color: white;
  top: 0;
  z-index: 3;
  transition: height 0.3s; }
  header#header.scrolled {
    box-shadow: 0px -12px 20px 10px;
    height: 5.6rem; }
  header#header .container {
    max-height: 100%;
    position: relative; }
  header#header .container-flex {
    margin-top: 3rem;
    margin-bottom: 3rem;
    transition: margin 0.3s; }
  header#header.scrolled .container-flex {
    margin-top: 1.3rem; }
  header#header h1 {
    font-size: 40px;
    font-weight: normal;
    line-height: 44px;
    text-align: center;
    margin-bottom: 18px; }
    @media (min-width: 500px) {
      header#header h1 {
        font-size: 60px;
        line-height: 64px; } }
  header#header h3 {
    font-size: 24px;
    font-weight: normal;
    text-align: center;
    margin-bottom: 60px; }
  header#header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    header#header ul li {
      cursor: pointer;
      padding: 6px 20px;
      font-size: 48px; }
      @media (max-width: 1199.98px) {
        header#header ul li {
          font-size: 32px; } }
  header#header a {
    color: #fff; }
  header#header p {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 18px; }
    header#header p span {
      position: relative;
      display: block;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 20px;
      margin-bottom: 24px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 4px; }
      header#header p span:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -12px;
        margin: auto;
        background-color: #70b960;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 3px; }
      header#header p span:after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: -2px;
        bottom: 0;
        left: -6px;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 8px 6px;
        border-color: transparent transparent white transparent; }
  header#header div.inflow {
    width: 60px;
    height: 60px;
    position: relative; }
  header#header button#shareButton {
    transition: transform 0.4s ease-out;
    display: flex; }
    header#header button#shareButton:before, header#header button#shareButton:after {
      content: none; }
    .open header#header button#shareButton {
      z-index: -1; }
    header#header button#shareButton.open {
      transform: translate3d(0, 0, 0) rotate(180deg); }
  header#header button {
    padding: 0;
    opacity: 0.6;
    background-color: transparent;
    position: relative;
    z-index: 2;
    border: none;
    width: 82px;
    height: 79px;
    outline: none;
    transition: opacity 0.2s ease-out, height 0.3s;
    --open-image-src: '';
    --close-image-src: '';
    --open-width: 82px;
    --close-width: 82px; }
    header#header button img {
      transition: height 0.3s;
      max-height: 100%;
      position: relative;
      display: block;
      max-width: 100%; }
    header#header button:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      right: auto;
      width: var(--open-width);
      max-width: 100%;
      background: var(--open-image-src) no-repeat center;
      background-position-y: center;
      transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out; }
    header#header button:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      right: auto;
      width: var(--close-width);
      background: var(--close-image-src) no-repeat;
      transition: opacity 0.4s ease-out;
      opacity: 0; }
    header#header button:active {
      transform: translateY(2px); }
    header#header button:hover {
      opacity: 1; }
    .open header#header button {
      opacity: 1; }
      .open header#header button:before {
        opacity: 1;
        width: 0; }
      .open header#header button:after {
        opacity: 1;
        transform: translate3d(0, 0, 0) rotate(360deg);
        transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out; }
  header#header nav {
    font-family: "SketchBlockBold", sans-serif, sans-serif;
    z-index: 1;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    overflow: hidden; }
    header#header nav:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background: rgba(86, 160, 70, 0.98);
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      border-radius: 100%;
      transform: scale(0.04), translateY(9999px);
      overflow: hidden; }
    .open header#header nav {
      top: 0; }
      .open header#header nav:before {
        animation: menu-animation 0.8s ease-out forwards; }
  header#header ul.menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    backface-visibility: hidden;
    perspective: 1000;
    color: white; }
    @media (max-width: 1199.98px) {
      header#header ul.menu {
        width: 100%; } }
    header#header ul.menu li {
      opacity: 0;
      text-align: center;
      transform: translate3d(0, 36px, 0); }
      header#header ul.menu li:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        left: auto;
        background-color: white;
        height: 100%;
        width: 0;
        overflow: hidden;
        transition: width 0.14s ease-out; }
      header#header ul.menu li:after {
        opacity: 0;
        content: attr(data-text);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        color: #70b960;
        overflow: hidden;
        transform: translate(-24px, 6px);
        transition: transform 0.1s ease-out, opacity 0.1s ease-out; }
      header#header ul.menu li:hover a {
        color: #003a5d; }
      .open header#header ul.menu li {
        opacity: 1;
        transform: translate3d(0, 0, 0) skewY(-9deg) rotateY(-9deg);
        transition: transform 0.2s ease-out, opacity 0.2s ease-out; }
        .open header#header ul.menu li:nth-child(1) {
          transition-delay: 0.75s; }
        .open header#header ul.menu li:nth-child(2) {
          transition-delay: 0.85s; }
        .open header#header ul.menu li:nth-child(3) {
          transition-delay: 0.95s; }
        .open header#header ul.menu li:nth-child(4) {
          transition-delay: 1.05s; }
  header#header article {
    padding: 24px;
    max-width: 600px;
    margin: 60px auto;
    transition: 0.2s ease-out; }

@keyframes menu-animation {
  0% {
    opacity: 0;
    transform: scale(0.04) translateY(300%); }
  40% {
    transform: scale(0.04) translateY(0);
    transition: ease-out; }
  40% {
    transform: scale(0.04) translateY(0); }
  60% {
    opacity: 1;
    transform: scale(0.02) translateY(0px); }
  61% {
    transform: scale(0.04); }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%; }
  100% {
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0; } }

.open .content-wrapper {
  transform: scale(0.92);
  transition: transform 0.2s 0.41s ease-out; }

#share {
  position: absolute;
  top: 100px;
  right: 24px;
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.8s;
  background-color: grey;
  border-radius: 4px;
  z-index: 5; }
  .scrolled #share {
    top: 70px; }
  #shareButton.open ~ #share {
    max-height: 500px; }
    .open #shareButton.open ~ #share {
      transition: z-index 0s 0.5s;
      z-index: -1; }

#headerLogo {
  flex: 0 1 auto;
  display: flex;
  max-width: 50%;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 15px; }
  #headerLogo > img {
    transition: height 0.3s, width 0.3s;
    width: auto;
    max-width: 100%;
    height: auto; }
  .scrolled #headerLogo > img {
    height: 3.6rem;
    transform: translateY(-3px); }

header#header.scrolled button {
  height: 3rem; }

.glueckspost a {
  font-size: 2rem;
  font-family: "SketchBlockBold", sans-serif;
  color: #70b960;
  text-align: center;
  display: block;
  margin: 0 auto 50px; }
  .glueckspost a:hover {
    text-decoration: none;
    color: white; }
  @media (min-width: 992px) {
    .glueckspost a {
      margin: 0 auto 100px; } }

.glueckspost .wettbewerb {
  max-width: 100%;
  margin-bottom: 100px;
  height: auto; }
  @media (max-width: 991.98px) {
    .glueckspost .wettbewerb {
      margin-bottom: 50px; } }

.glueckspost .textBlock {
  padding-top: 0; }

.glueckspost #wettbewerb_container {
  padding-bottom: 100px; }

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block; }

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em; }

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  display: flex;
  justify-content: center;
  align-content: center; }

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin: 0 auto;
  vertical-align: top; }

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle; }

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none; }

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none; }

.resp-sharing-button--twitter {
  background-color: #55acee; }

.resp-sharing-button--twitter:hover {
  background-color: #2795e9; }

.resp-sharing-button--pinterest {
  background-color: #bd081c; }

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615; }

.resp-sharing-button--facebook {
  background-color: #3b5998; }

.resp-sharing-button--facebook:hover {
  background-color: #2d4373; }

.resp-sharing-button--tumblr {
  background-color: #35465C; }

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c; }

.resp-sharing-button--reddit {
  background-color: #5f99cf; }

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1; }

.resp-sharing-button--google {
  background-color: #dd4b39; }

.resp-sharing-button--google:hover {
  background-color: #c23321; }

.resp-sharing-button--linkedin {
  background-color: #0077b5; }

.resp-sharing-button--linkedin:hover {
  background-color: #046293; }

.resp-sharing-button--email {
  background-color: #777; }

.resp-sharing-button--email:hover {
  background-color: #5e5e5e; }

.resp-sharing-button--xing {
  background-color: #1a7576; }

.resp-sharing-button--xing:hover {
  background-color: #114c4c; }

.resp-sharing-button--whatsapp {
  background-color: #25D366; }

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851; }

.resp-sharing-button--hackernews {
  background-color: #FF6600; }

.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {
  background-color: #FB6200; }

.resp-sharing-button--vk {
  background-color: #507299; }

.resp-sharing-button--vk:hover {
  background-color: #43648c; }

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998; }

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373; }

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee; }

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9; }

.resp-sharing-button--google {
  background-color: #dd4b39;
  border-color: #dd4b39; }

.resp-sharing-button--google:hover,
.resp-sharing-button--google:active {
  background-color: #c23321;
  border-color: #c23321; }

.resp-sharing-button--tumblr {
  background-color: #35465C;
  border-color: #35465C; }

.resp-sharing-button--tumblr:hover,
.resp-sharing-button--tumblr:active {
  background-color: #222d3c;
  border-color: #222d3c; }

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777; }

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e; }

.resp-sharing-button--whatsapp {
  background-color: #25D366;
  border-color: #25D366; }

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1DA851;
  border-color: #1DA851; }

.resp-sharing-button--telegram {
  background-color: #54A9EB; }

.resp-sharing-button--telegram:hover {
  background-color: #4B97D1; }

@keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes lds-eclipse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.lds-eclipse {
  position: relative;
  margin: 0 auto; }

.lds-eclipse div {
  position: absolute;
  -webkit-animation: lds-eclipse 1s linear infinite;
  animation: lds-eclipse 1s linear infinite;
  width: 160px;
  height: 160px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 #70b960;
  -webkit-transform-origin: 80px 82px;
  transform-origin: 80px 82px; }

.lds-eclipse {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px); }

@media (min-width: 992px) {
  .dd-tabcollapse-acc {
    display: none; } }

@media (max-width: 991.98px) {
  .dd-tabcollapse-tab {
    display: none; } }

.accordion .card {
  display: block; }

.toolbox-accordion .tab-content {
  padding: 0 1rem; }

.toolbox-accordion.component-tab .nav.nav-tabs {
  margin-bottom: 1rem; }

.toolbox-accordion .nav-tabs .nav-link.active, .toolbox-accordion .nav-tabs .nav-item.show .nav-link {
  background: #70b960; }

.toolbox-accordion .card-header {
  padding: 0; }
  .toolbox-accordion .card-header a {
    display: block;
    padding: 0.75rem 1.25rem;
    position: relative; }
    .toolbox-accordion .card-header a:after {
      color: #003a5d;
      position: absolute;
      right: 10px; }
    .toolbox-accordion .card-header a, .toolbox-accordion .card-header a:focus, .toolbox-accordion .card-header a:active {
      text-decoration: none; }

.toolbox-accordion .nav-tabs .nav-link {
  border-radius: 0; }

.toolbox-accordion .card, .toolbox-accordion .card-header {
  border-radius: 0; }

.toolbox-accordion .card-header {
  background: #e9ecef;
  border: none; }

a.toolbox-anchor[id] {
  display: inline-block;
  position: relative;
  /*

    Activate this, if you have a static header to add a padding to anchor headlines.

    &:before {
      width:0;
      display: block;
      content: " ";
      margin-top: -175px;
      height: 175px;
      visibility: hidden;
      z-index: -1;
    }

  */ }

.toolbox-columns.equal-height > .row > div {
  display: flex; }
  .toolbox-columns.equal-height > .row > div .toolbox-column {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column; }
    .toolbox-columns.equal-height > .row > div .toolbox-column > .toolbox-image {
      overflow: hidden; }

.toolbox-columns.column-1-grey .row > div:nth-child(1) .toolbox-column {
  padding: 15px;
  background: #e9ecef; }

.toolbox-columns.column-2-grey .row > div:nth-child(2) .toolbox-column {
  padding: 15px;
  background: #e9ecef; }

.toolbox-columns.column-3-grey .row > div:nth-child(3) .toolbox-column {
  padding: 15px;
  background: #e9ecef; }

.toolbox-columns.column-4-grey .row > div:nth-child(4) .toolbox-column {
  padding: 15px;
  background: #e9ecef; }

.toolbox-container.bg-container {
  background: #e9ecef;
  padding-top: 30px;
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    .toolbox-container.bg-container {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (min-width: 1200px) {
    .toolbox-container.bg-container {
      padding-top: 47px;
      padding-bottom: 58px; } }

.toolbox-headline a[id] {
  display: inline-block;
  position: relative;
  /*

        Activate this, if you have a static header to add a padding to anchor headlines.

        &:before {
          width:0;
          display: block;
          content: " ";
          margin-top: -175px;
          height: 175px;
          visibility: hidden;
          z-index: -1;
        }

      */ }

.toolbox-image {
  line-height: 1; }
  .toolbox-image img {
    width: 100%;
    height: auto; }
  .toolbox-image .caption {
    margin: 5px 0 8px 0;
    display: block;
    color: #6c757d;
    font-size: small; }

.toolbox-linklist ul li {
  position: relative;
  padding-left: 25px; }
  .toolbox-linklist ul li a {
    display: block;
    color: #212529; }
    .toolbox-linklist ul li a:hover, .toolbox-linklist ul li a:focus, .toolbox-linklist ul li a:active {
      color: #007bff;
      text-decoration: none; }
    .toolbox-linklist ul li a:before {
      position: absolute;
      left: 3px;
      font-size: 20px;
      color: #212529; }

.toolbox-slide-columns > .row > .slide-columns {
  display: flex;
  overflow: hidden; }

.toolbox-spacer {
  margin: 0;
  padding: 0;
  line-height: 0; }
  .toolbox-spacer span {
    display: inline-block;
    width: 100%;
    height: 1px; }
    .toolbox-spacer span.spacer-50 {
      margin-top: 49px; }

.equal-height .toolbox-teaser {
  display: flex;
  flex: 1;
  flex-basis: auto;
  flex-direction: column; }
  .equal-height .toolbox-teaser > .row {
    display: flex; }
    .equal-height .toolbox-teaser > .row > div {
      display: flex;
      flex-basis: auto; }
  .equal-height .toolbox-teaser .single-teaser {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    max-width: 100%;
    min-width: 10px; }
    .equal-height .toolbox-teaser .single-teaser .bottom {
      margin-top: auto; }

.toolbox-teaser {
  background: #e9ecef; }
  .toolbox-teaser img {
    width: 100%; }
  .toolbox-teaser .teaser-content {
    padding: 15px; }

.toolbox-video {
  padding-bottom: 56.2666667%;
  position: relative;
  max-width: 100%; }
  .toolbox-video .video-inner {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }
    .toolbox-video .video-inner iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .toolbox-video .poster-overlay {
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    background-size: cover;
    padding-bottom: 56.2666667%; }
    .toolbox-video .poster-overlay .icon {
      position: absolute;
      font-size: 80px;
      color: #fff;
      text-shadow: 0 0 3px #000;
      line-height: 1; }

